.Banner {
    width: 100%;
    position: fixed;
    top: 56px;
    left: 0;
    z-index: 100;
    /* margin: auto; */
    /* height: 40px; */
    /* line-height: 40px; */
    /* background-color: #28a746; */
    /* color: white; */
    text-align: center;
}

.Hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
    z-index: -100;
}

.Visible {
    visibility: visible;
    /* transition: opacity .1s ease-in; */
    opacity: 1;
    /* transition: opacity 2s linear; */
  }  

/* The sticky class is added to the header with JS when it reaches its scroll position */
.Sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}
  
/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
/* .Sticky + .Banner {
padding-top: 102px;
}   */
.CentreMgmt {
    margin: auto;
    padding: 25px;
}





.HeaderButtonRow {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}
.Calendar {
    width: 100%;
    margin: auto;
    padding: 5px;
    color: #464646;
}

.CalendarInfoBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: bold;
    margin-bottom: 10px;
    color: #9d9c9c;
    padding: 0;
    position: relative;
    /* height: 70px; */
}

.AscKey {
    color:blue;
}

.BscKey {
    color:pink;
}

.VacKey {
    color:green;
}

.ExcKey {
    color: #88dab5;
}

.text {
    color: rgb(136, 218, 181);
}

.ExcursionTitle {
    /* text-decoration: underline; */
    padding-bottom: 10px;
}

.ExcursionRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5px;
    border-bottom: 1px solid lightgrey;
    /* border-radius: 5px; */
    margin-bottom: 5px;
}

.ExcursionRow:hover {
    background-color: rgb(250, 250, 250);
}

.ExcursionKey {
    font-weight: bold;
    min-width: 35%;
    color: #152153;
}

.Key {
    position: relative;
    color: #464646;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    border: 1px solid lightgrey;
    border-radius: 0px;
    font-size: .6rem;
}
.AccountMgmt {
    position: relative;
    padding: 5px;
    /* max-width: 90%; */
    margin: auto;
    text-align: left;
}


.Label {
    font-weight: bold;
    font-size: .7rem;
}



.HeaderButtonRow {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    padding-bottom: 10px;
}

.HeaderButtonRowColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
    padding-bottom: 10px;    
    width: 400px;
}

.HeaderButtonRowRightAbsolute {
    top: 5px;
    right: 5px;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}

.HeaderButtonRowRight {
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}


.EditChildDetailsContainer {
    border: 1px solid lightgrey; 
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
}

.ACCSSummary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-color: #15375b; */
    background-color: var(--blue);
    color: white;
    padding: 10px;
    border-radius: 5px;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    padding: 2px 0 2px 0;
}

.Row span {
    font-weight: bold;
}

.Row input {
    font-size: .8rem;
}

.Item {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
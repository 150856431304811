.ServiceProfiles {
    position: relative;
    /* overflow: hidden; */
}

.ServiceProfile {
    background-color: grey;
    color: white;
    position: relative;
    border: 1px solid lightgrey;
    padding: 25px;
    /* padding-top: 20px; */
    border-radius: 5px;
    margin: 30px auto;    
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    padding: 2px 0 2px 0;
}

.Row span {
    font-weight: bold;
}

.Row input {
    font-size: .8rem;
}

.Item {
    min-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
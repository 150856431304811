.CRM {
    position: relative;
    padding: 5px;
    /* max-width: 90%; */
    margin: auto;
    text-align: left;
}


.Label {
    font-weight: bold;
    font-size: .7rem;
}



.HeaderButtonRow {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    padding-bottom: 10px;
}

.HeaderButtonRowRight {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}

.NoteInputContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: stretch;
}

.NoteInput {
    flex: 1 1;
}
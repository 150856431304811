
.HeaderButtonRow {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    padding-bottom: 10px;
}

.HeaderButtonRowRightAbsolute {
    top: 5px;
    right: 5px;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}

.HeaderButtonRowRight {
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}


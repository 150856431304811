.Personnel {
    position: relative;
    /* overflow: hidden; */
}

.Person {
    background-color: grey;
    color: white;
    position: relative;
    border: 1px solid lightgrey;
    padding: 25px;
    /* padding-top: 20px; */
    border-radius: 5px;
    margin: 30px auto;
}

.Person button {
    font-size: 0.8rem;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    padding: 2px 0 2px 0;
}


.Row span {
    font-weight: bold;
}

.Row input {
    font-size: .8rem;
}

.Row select {
    
    font-size: .8rem;
}


.Item {
    min-width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.RoundButton {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    font-size: 2rem;
    /* line-height: 25px; */
    border: none;
    background-color: #17A2B8;
    color: white;
    font-weight: bold;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}


.SupportingEvidence {
    text-align: center;
}
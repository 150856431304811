.App {
  text-align: center;
}

.App button {
    font-size: .8rem;
    /* background-color: #3A8FnCC; */
    /* border: 1px solid #66BC45; */
    /* color: #66BC45; */
}

/* override bootstrap form class default */
.form-control {
    font-size: .8rem;
}

/* override Material UI classes */


.MuiTab-root {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

/* ------------------------------------------ */
/* CCS overrides for form.io forms */
.App input[ref] {
    font-size: .8rem;
}
.App select[ref] {
    font-size: .8rem;
}
.App div[data-value] span {
    font-size: .8rem;
}
/* ------------------------------------------ */


/* Unviersal styles */

:root {
    --blue: #2786A8;
    --blue-tinted: #2786A8af;
    --green: #84CE25;
    --green-tinted: #85ce25af;
    --darkgreen: darkgreen;
    --darkgreen-tinted: #006400af;
    --purple: #4e216a;
    --purple-tinted: #4e216aaf;
    --red: #cf3333;
    --red-tinted: #cf3333af;
    --darkgrey: #464646;
    --darkgrey-tinted: #464646af;
    --lightgrey: rgb(243, 243, 243);
    --actionBackground: #cf333330;
    --approvedBackground: #7dd95630;
    --pendingBackground: #c9c9c9;
    --amber: #FFC000;
    --amber-slightly-tinted: #ffbf00c1;
    --amber-tinted: #FFC000af;  
    --orange: #fd8c00;
    --orange-tinted: #fd8c00af;
}

.CustomButton {
    padding: 4px;
    /* margin: -3px; */
    /* width: 100%; */

}

.CustomButtonPrimary {
    border: 1px solid var(--blue);
    background-color: white;
    color: var(--blue);
}

.CustomButtonPrimary:hover {
    background-color: var(--green);
    border: 1px solid var(--green);
    color: white;
}

.CustomButtonPrimary:focus {
    background-color: var(--green);
    border: 1px solid var(--green);
    color: white;
}

.CustomButtonSecondary {
    border: 1px solid var(--blue);
    background-color: var(--blue);
    color: white;    
}

.CustomButtonSecondary:hover {
    background-color: var(--blue);
    border: 1px solid var(--blue);
    color: white;
}

.CustomButtonSecondary:focus {
    background-color: var(--blue);
    border: 1px solid var(--blue);
    color: white;
}

/* custom buttons for roll screens */
.CustomButtonSignOut {
    background-color: var(--purple-tinted);
    color: white;
    font-weight: bold;
}

.CustomButtonSignOut:hover {
    background-color: var(--purple);
    border: 1px solid var(--purple);
    color: white;
}

.CustomButtonSignOut:focus {
    background-color: var(--purple);
    border: 1px solid var(--purple);
    color: white;
}

.CustomButtonSignIn {
    background-color: var(--green-tinted);
    color: darkslategray;
    font-weight: bold;
}

.CustomButtonSignIn:hover {
    background-color: var(--green);
    border: 1px solid var(--green);
    color: darkslategray;
}

.CustomButtonSignIn:focus {
    background-color: var(--green);
    border: 1px solid var(--green);
    color: darkslategray;
}


.CustomButtonAbsent {
    background-color: var(--blue-tinted);
    color: white;
    font-weight: bold;
}

.CustomButtonAbsent:hover {
    background-color: var(--blue);
    border: 1px solid var(--blue);
    color: white;
}

.CustomButtonAbsent:focus {
    background-color: var(--blue);
    border: 1px solid var(--blue);
    color: white;
}

.CustomButtonHeadcount {
    background-color: var(--orange-tinted);
    color: white;
    font-weight: bold;
}

.CustomButtonHeadcount:hover {
    background-color: var(--orange);
    border: 1px solid var(--orange);
    color: white;
}

.CustomButtonHeadcount:focus {
    background-color: var(--orange);
    border: 1px solid var(--orange);
    color: white;
}

.CustomButtonDrill {
    background-color: var(--red-tinted);
    color: white;
    font-weight: bold;
}

.CustomButtonDrill:hover {
    background-color: var(--red);
    border: 1px solid var(--red);
    color: white;
}

.CustomButtonDrill:focus {
    background-color: var(--red);
    border: 1px solid var(--red);
    color: white;
}

.CustomButtonAmber {
    background-color: var(--amber-slightly-tinted);
    color: darkslategray;
    font-weight: bold;
    
}

.CustomButtonAmber:hover {
    background-color: var(--amber);
    border: 1px solid var(--amber);
    color: darkslategray;
}

.CustomButtonAmber:focus {
    background-color: var(--amber);
    border: 1px solid var(--amber);
    color: darkslategray;
}

/* end of custom buttons for roll */

.btn-success {
    background-color: var(--green);
    border: 1px solid var(--green);
    color: white;
}

.global-complete {
    background-color: var(--darkgreen-tinted);
    color: white;    
}
.global-goodToGo {
    background-color: var(--green-tinted);
    color: white;    
}
.global-neutral {
    background-color: var(--darkgrey-tinted);
    color: white;    
}

.global-danger {
    /* background-color: rgb(255, 212, 212); */
    background-color: var(--red-tinted);
    color: white;
}

.global-warning {
    /* background-color: rgb(255, 212, 212); */
    background-color: var(--amber-tinted);
    color: white;
}

.global-highlight {
    background-color: var(--purple-tinted);
    color: white;

}

/* .Wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 20px;
    font-size: .8rem;
    text-align: left;
    gap: 20px;
} */

/* .LeftBar {
    flex: 1 1 150px;
    background-color: white;
    max-height: 80vh;
} */

/* .Content {
    flex: 12 1 450px;
    overflow-x: auto;
} */

/* .Wrapper > div {
    margin: 10px;
} */

/* .LeftBar > button {
    font-size: .8rem
} */

/* @media only screen and (min-width: 768px) {
    .Wrapper {
        max-width: 100vw;
        margin: 20px;
    }

    .LeftBar {
        position: -webkit-sticky;
        position: sticky;
        top: 20px;
        padding-right: 10px;
        border-right: 1px solid lightgrey;
    }


} */





/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* -------------------------------------------------- */
/* FORM.IO OVERRIDES */



/* Attempt to override form.io error classes */
.error {
  color: white !important; 
  background-color: rgb(201, 137, 137) !important;
  padding: 10px;
  border-radius: 3px;
}

.formio-condensed {
    margin: 0;
    /* margin-bottom: -5px; */
}

.formio-bold-input-label {
    font-weight: 700;
    font-size: 1.1rem;
}

@media only screen and (max-width: 732px) {
    .card-body {
        padding: 0.2rem;
    }
}

/* -------------------------------------------------- */


/* Override full-calendar on hover class */
.fc-event:hover {
    cursor: pointer;
}

.fc .fc-list-event-dot {
    display: none !important;
}

.formio-enrolment-form-table h5 {
    font-size: .7rem;
}

.fc-list-day {
    position: relative;
    z-index: 10;
}
.fc-list-day a {
    color: darkslategray;
}

/* .formio-enrolment-form-table .table {
  table-layout: fixed;
  width: 100%;
} */

/* .formio-error-wrapper {
  background-color: white !important; 
  color: white;
} */

/* .is-invalid {
  border-width: 2px !important;
} */


/* Overrides of react bootstrap table v2 classes */


/* override react-multi-date-picker hover on mobile which causes hover to stay on after a tap */

/* @media (hover: none) {
    .rmdp-day:not(.rmdp-selected):not(.rmdp-today) span {
        background-color: white !important;
        color: black !important;
    }
} */

.rmdp-day:not(.rmdp-selected) span:hover {
    background-color: white !important;
    color: black !important;
}


.rmdp-today:not(.rmdp-selected) span {
    background-color: white !important;
    color: black !important;
}

.rmdp-today span {
    border: 1px solid darkred !important;
}



/* overrides of react day picker */
.rdp {
    position: relative;
    z-index: 0;
}

.rdp-day_today {
    border: 2px solid darkred !important;
    
}

/* overrides for TinyEditor */
.tox-tinymce {
    z-index: 0;
}


/* ------------------------------------- */
/* GENERAL LAYOUT CLASSES */

:root {
    --leftBarWidth: 250px;
}

.Layout {
    margin: 10px auto;
}

/* for very large screens, limit to 90% */
@media only screen and (min-width: 1500px) {
    .Layout {
        max-width: 95%;

    }
}

.Header {
    padding-left: 1rem;
    display: flex; 
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* background-color: rgba(1, 168, 229, 0.164); */
    /* color: white; */
    height: 75px;
    /* border-bottom: 1px solid white; */
}



.Icon {
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-Items: center;
}

/* mobile */
.Body {
    margin: 20px 10px;
    text-align: left;
    padding-bottom: 50px; /* to make sure fixed position mobile navbar doesn't cover content */
}

/* desktop */
@media only screen and (min-width: 733px) {
    .Body {
        margin: 20px;

    }
}

/* ------------------------------------- */




/* ------------------------------- */
/* LEFT BAR CLASSES */

.Logo {
    margin: 10px;
    height: 56px;
    margin-bottom: 50px;
}

.LeftBar {
    background-color: var(--lightgrey);
    width: var(--leftBarWidth);
    height: 100%;
    border-right: 1px solid var(--lightgrey);
    padding: 0 10px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    z-index: 90;
    overflow-y: auto;
}

.LeftBarPadding {
    padding-left: var(--leftBarWidth);
}

.LeftBarLinks {
    border: 1px solid var(--green);
    border-radius: 10px;
    padding: 10px;
    min-width: 40px;
    background-color: white;
    
}

/* the important here overwrites a top: -1px from .btn > .badge class in Bootstrap */
.TopRightBadge {
    right: 10%;
    top: 5px !important; 
}

/* .LinkHeaderContainer {
    padding-bottom: 25px;
} */

.LinkHeader {
    color: darkslategray;
    text-align: left;
}
/* ------------------------------- */






/* ------------------------------- */
/* MOBILE FOOTER CLASSES */


.MobileFooter {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    padding: 0 10px;
    align-items: center;
    position: fixed;
    bottom: 0;
    box-shadow: 0px 0px 10px 2px  var(--lightgrey);
    /* border-top: 1px solid rgb(230, 230, 230); */
    background-color: var(--lightgrey);
    z-index: 90;
}

.MobileSubLink {
    /* border: 1px solid var(--white); */
    border-radius: 10px;
    padding: 10px;
    /* min-width: 40px; */
    flex: 1;
    /* background-color: white; */
    font-weight: bold;
    color: darkslategray;
    font-size: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;
}

.FooterNotificationIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;    
    width: 60px;
}

.ActiveLink {
    color: var(--green);
}

/* ------------------------------- */
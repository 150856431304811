.Entitlements {
    min-height: 50vh;
}

.WarningText {
    font-weight: 550;
    color: var(--red);
}


.InfoText {
    font-weight: 550;
    font-style: italic;
}

.RedText {
    color: var(--red);
    
}
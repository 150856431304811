.Notifications {
    position: fixed;
    z-index: 500;
    background-color: white;
    box-shadow: 5px 5px 10px #504f4f;
    border-radius: 5px;
    font-size: .8rem;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    text-align: left;
    

}


.NotificationsMobile {
    left: 10%;
    bottom: 50px;
    transition: all 0.3s ease-in;
    width: 80%;
    height: 80%;
}

.NotificationsDesktop {
    left: 30%;
    top: 56px;
    transition: all 0.3s ease-in;
    
    width: 50%;
    min-width: 300px;
    max-width: 500px;
    height: 70%;
}

.Notification {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    border-bottom: 4px solid white;
    padding: 10px;
    font-size: .7rem;
    /* background-color: var(--lightgrey); */
}

.Notification:hover {
    cursor: pointer;
}
.TextContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;    
}

.Action {
    background-color: var(--actionBackground);

}

.Pending {
    background-color: var(--pendingBackground);

}

.Approved {
    background-color: var(--approvedBackground);
}

.Title {
    font-size: .75rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: darkslategrey;
}
.Subtitle {
    font-weight: 550;
    font-size: .9rem;
    letter-spacing: 1px;
}

.Tile {
    position: relative;
    margin-bottom: 50px;
    /* padding: 20px 0; */
    border-bottom: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: 2px 2px 10px grey;
    padding: 20px;
    background-color: white;
    margin: 20px auto;
}

.Label {
    font-weight: 500;
    letter-spacing: .5px;
}

.FlexRow {
    display: flex;
    flex-flow: row wrap;
    gap: 5px;
    justify-content: space-between;
    padding: 10px auto;
    margin: 20px auto;
}

.FlexRowItem {
    flex: 1 1 150px;
}

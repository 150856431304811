.AdminMgmt {
    background-color: white;
    margin: auto;
    /* border: 1px solid lightgrey;  */
    /* border-radius: 5px; */
    padding: 25px;
    box-sizing: border-box;
    text-align: left;
    overflow-x: auto;
    min-height: 100%;
    /* position: relative; */
}


.HeaderButtonRow {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}
.Dashboard {
    position: relative;
    padding: 5px;
    /* max-width: 90%; */
    margin: auto;
    text-align: left;
}


.Label {
    font-weight: bold;
    font-size: .7rem;
}



.HeaderButtonRowLeft {
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    padding-bottom: 10px;
}
.AdditionalParams {
    border: 1px solid grey;
    padding: 10px;
    border-radius: 5px;
    max-width: 550px;
    /* margin-right: 0; */
    /* margin-left: auto; */
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 2px 0 2px 0;
}

.Label {
    font-weight: bold;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.RoundButton {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    font-size: 2rem;
    /* line-height: 25px; */
    border: none;
    /* background-color: #17A2B8; */
    color: white;
    font-weight: bold;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.Row {
    display: flex;
    flex-direction: row;
    margin: 10px auto;
    justify-content: flex-start;
}

.Label {
    font-size: .7rem;
    flex-basis: 30%;
}

.Input {
    /* flex: 1 0 80px; */
    flex-basis: 30%;
    font-size: .7rem;
}


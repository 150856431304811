.RollActivityLog {
    position: relative;
}

.HeaderButtonRowRight {
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
    position: absolute;
}
.SideDrawer {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    text-align: left;
    overflow-x: hidden;
}

@media (min-width: 733px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

.Logo {
    height: 11%;
    margin-bottom: 32px;
}


.InstallApp {
    max-height: 150px;
    border-radius: 4px;
    width: 100%;
    /* background-color: rgb(88, 218, 163); */
    background-color: rgb(88, 173, 218);
    color: white;
    align-items: center;
    text-align: center;
    padding: 10px;
    display: block;
    font-size: .9rem;
}

.InstallButton {
    background-color: white;
    font-weight: bold;
    /* color:  rgb(88, 218, 163); */
    color:  rgb(88, 173, 218);
    border: none;
    padding: 4px;
    min-width: 75px;
    margin: 0 auto;
    border-radius: 20px;
    font-size: .75rem;
    
}

.InstallDismiss {
    /* background-color: rgb(88, 218, 163);  */
    background-color: rgb(88, 173, 218);
    border: 1px solid white;
    color: #616161;
}

.Divider {
    width:5px;
    height:auto;
    display:inline-block;
}
.FormColumn {
    flex: 5 1 450px;
    overflow-x: auto;
}

.FormColumn > div {
    margin: 0 0 10px 0;
}

.Form {
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;  
    
}


.FormTitle {
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;  
    padding: 25px;
    text-align: left;
    /* height: 40px; */
    /* text-transform: capitalize; */
}


/* .Contact {
    width: 100%;
} */

.Container {
    padding: 10px;
}

.Title {
    font-weight: bold;
    font-size: 2rem;
    color: #00384D;
    letter-spacing: 1px;
}

.PlusColor {
    color: #7ED956;
}

.Hours {
    font-weight: bold;
    font-size: .85rem;
    color: grey;
    letter-spacing: 1px;
    padding: 3px 0;
}
.Info {
    font-weight: bold;
    font-size: .85rem;
    color: darkslategray;
    letter-spacing: .5px;
    padding: 3px 0;
}

.MinWidth {
    min-width: 180px;
    display: inline-block;
}

.HiddenLabel {
    color: white;
}

.Superscript {
    vertical-align: super;
    
}

.Button {
    width: 180px;
}

.PlusColorBackground {
    background-color: #7ED956;
    color: white;

}


@media only screen and (max-width: 480px) {
    .Subtitle {
        font-size: 5vw;
    }
}

.Value {
    padding: 3px;
    border-radius: 5px;
    font-weight: 300;
}

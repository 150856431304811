.CentreLink {
    position: relative;
    /* background-color: white; */
    margin: auto;
    /* border: 1px solid lightgrey;  */
    /* border-radius: 5px; */
    padding-top: 25px;
    box-sizing: border-box;
    text-align: left;
    overflow-x: auto;
    font-size: 0.8rem;
    /* min-height: 100vh; */
}



.Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    padding: 2px 0 2px 0;
}

.WeekSelect {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    padding: 2px 0 2px 0;    
    position: absolute;
    top: 25px;
    right: 25px;
}

.WeekSelectText {
    letter-spacing: .3px;
    color: darkslategray;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* min-width: 250px; */
    /* text-align: center; */
    margin: auto;
}


.HeaderButtonRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 5px;
    padding: 5px 0 15px 0;
}


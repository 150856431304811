.OptionModal {
    text-align: center;
    position: fixed;
    z-index: 500;
    width: 70%;
    background-color: white;
    border: 1px solid lightgrey; 
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;
    padding: 10px;
    left: calc(15%);
    top: 25%;
    box-sizing: border-box;
    overflow-y: auto;
    font-size: .8rem;
}

@media (min-width: 733px) {
    .OptionModal {
        width: 350px;
        left: calc(50% - 175px);
    }
}

.EnrolConfigForm {
    border: 1px solid lightgrey; 
    margin-top: 10px;
    border-radius: 5px;
    padding: 15px;
    box-sizing: border-box;
    text-align: left;
    overflow-x: auto;    
    margin: auto;
}

.InnerContainer {
    max-width: 550px;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 2px 0 2px 0;
}

.Row select {
    min-width: 100px;
}

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.Item {
    min-width: 150px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.BlockDatePicker, 
.BlockDatePicker > div {
   width: 100%;
}
.Approvals {
    /* position: relative; */
    /* background-color: white; */
    /* margin: auto; */
    /* border: 1px solid lightgrey;  */
    /* border-radius: 5px; */
    padding: 15px;
    /* box-sizing: border-box; */
    /* text-align: left; */
    /* overflow-x: auto; */
    /* position: relative; */
}



/* 
.Form {
    background-color: white;
    margin: auto;
    border: 1px solid lightgrey; 
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    text-align: left;
    overflow-x: auto;
} */

/* @media (min-width: 1200px) {
    .Approvals {
        width: 1000px;
        left: calc(50% - 500px);
    }
} */

.Link {
    color: blue;
}

.Link:hover {
    cursor: pointer;
}

.Notification {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 5px auto;
    padding: 5px;
    border-bottom: 1px solid rgb(231, 229, 229);
    /* border-radius: 5px; */
}

.Notification:hover {
    cursor: pointer;
}

.NotificationTitle {
    font-size: .85rem;
    /* padding: 5px; */
    /* font-weight: bold; */

}

.NotificationBadge {
    padding: 2px 8px;
    /* margin: 0 10px; */
    font-size: 0.7rem;
    font-weight: bold;
    color: darkslategrey;
    /* border-radius: 50%; */
    /* font-weight: bold; */
    border-bottom: 4px solid rgb(226, 68, 68);
    /* background: rgb(226, 68, 68); */
    /* color: white; */
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 2px 0 2px 0;
}

.Row select {
    min-width: 100px;
}

.Column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.Item {
    min-width: 150px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}



.HeaderButtonRowRight {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}

.HeaderButtonRow {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}

.InfoTextHeader {
    font-weight: 550;
}

.InfoText {
    font-weight: 550;
    font-style: italic;
}

.RedText {
    color: var(--red);
    
}
.ChildSummary {
    padding: 0;
    font-size: .7rem;
    position: relative;
}

.Table {
    border-collapse: collapse;
    width: 100%;
}

.Table td {
    padding: 3px 10px;
}

.TableKey {
    font-weight: bold;
    color: darkblue;
    width: 50%;
    /* min-width: 150px; */
}

.TableValue {
    color: black;
}

.DetailTableKey {
    font-weight: bold;
    color: darkblue;
    width: 25%;
    /* min-width: 150px; */
}

.DetailTableValue {
    color: black;
}

.FlexRowContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-around;
}

.FlexRowItem {
    width: 100%;

}

.ImageContainer {
    text-align: center;
}

.WarningText {
    color: red;
    font-weight: 550;
}

.SuccessText {
    color: darkgreen;
    font-weight: 550;
}

.ChildSummaryDetail {
    position: relative;
}

.SymbolsContainer {
    position: absolute;
    top: 15px;
    right: 0;    
    display: flex;
    flex-direction: row;
    gap: 10px;  
    justify-content: flex-end;
}

.HeaderButtonRowRight {
    position: absolute;
    top: 0;
    right: 0;
    
}
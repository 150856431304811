.NotificationIcon {
    color: white;
    text-align: center;
}

.NotificationIcon:hover {
    cursor: pointer;
}

.Icon {
    color: darkslategrey;
}

.Icon:hover {
    color: darkslategrey;
}

.CreditHeader {
    background-color: var(--green-tinted);
    padding: 10px;
    color: white;
    border-radius: 5px;
}
.DebitHeader {
    background-color: var(--red-tinted);
    padding: 10px;
    color: white;
    border-radius: 5px;
}


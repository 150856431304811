.TableContainer {
    background-color: white;
    /* padding: 25px; */
    position: relative;
    border-radius: 5px;
    /* margin: 10px auto; */
    /* border: 1px solid lightgrey;  */

}

.HeaderButtonRow {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    padding-bottom: 10px;
}

  

.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    /* overflow: hidden; */
    /* white-space: nowrap; */
}

.NavigationItem a {
    color: #00384D; 
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a span {
    color: #00384D;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    /* color: black; */
    color: var(--green);
    /* text-decoration: underline var(--green); */
    font-weight: bold;
}


.Clickable:hover {
    cursor: pointer;
    border-top:4px solid var(--green);
    color: black;  
}

.Info {
    /* color: rgb(114, 114, 114);   */
    color: var(--green);  
    border: 1px solid lightgrey;
    padding: 6px;
    border-radius: 4px;
}

@media (min-width: 733px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
        font-weight: bold;
    }
    
    .NavigationItem a {
        color: var(--blue);
        height: 100%;
        padding: 16px 10px;
        border-top: 4px solid transparent;
    }

    .NavigationItem span {
        /* color: white; */
        height: 100%;
        padding: 16px 10px;
        border-top: 4px solid transparent;
    }
    
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        border-top:4px solid var(--green);
        color: black;
    }

    .Clickable {
        color: var(--blue)
    }


    .Info {
        /* color: var(--blue);   */
        border: none;
        padding: 0;
        /* border-radius: 0px; */
    }    
    

}


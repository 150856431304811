.Toolbar {
    height: 56px;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    /* background-color: rgb(61, 61, 61); */
    /* background-color: rgb(255, 255, 255); */
    /* background-color: rgba(1, 168, 229, 0.164); */
    display: flex;
    justify-content: space-between;
    /* justify-content: flex-end; */
    /* gap: 5px; */
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.FlexEnd {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.NotificationIcon {
    text-align: center;
    width: 80px;
}

.Toolbar nav {
    height: 100%;
}

.Logo {
    height: 100%;
}

@media (max-width: 733px) {
    .DesktopOnly {
        display: none;
    }
}
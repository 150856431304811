
.BannerContainer {
	width: 50px;
	height: 50px;
	overflow: hidden;
	position: absolute;
	top: 0px;
	left: 0px;
}

.BannerContainer::after {
    content: "";
    display: block;
    width: 10px;
    height: 5px;
    background-color: darken(var(--green), 5%);
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.BannerContainer::before {
    content: "";
    display: block;
    height: 10px;
    width: 5px;
    background-color: darken(var(--green), 5%);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.Banner {
	width: 60px;
	padding: 1px;
	background-color: var(--green);
	color: #fff;
	text-align: center;
	transform: rotate(-45deg) translate(-28%, -35%);
	box-shadow: 0px 5px 5px rgba(0,0,0,0.4);
	text-transform: uppercase;
	text-shadow: 0 2px 2px rgba(0,0,0,0.4);
}


.AccountBalanceContainer {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    padding-top: 10px;
}

.AccountBalance {
    /* margin-top: 10px; */
    padding: 10px 25px;
    /* background-color: grey; */
    box-shadow: 1px 1px 2px grey;  
    color: rgb(124, 124, 124);
    text-align: center;
    font-size: .7rem;
    letter-spacing: 0.8px;
    font-weight: bold;
}

.NegativeBalance {
    color: red;
    font-size: .7rem;
}
.PositiveBalance {
    color: green;
    font-weight: bold;
    font-size: .7rem;
}

.LastUpdated {
    font-weight: normal;
    font-size: .6rem;
    letter-spacing: normal;
}
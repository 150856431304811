.DailyRoll {
    box-sizing: border-box;
    text-align: left;
    font-size: 0.8rem;
    max-width: 90%;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin: auto;
    /* background-color: white; */
    /* box-shadow: 5px 5px 10px #504f4f; */
    padding: 10px;
}

/* desktop */
@media only screen and (min-width: 733px) {
    .DailyRoll {
        max-width: 800px;
    }
}
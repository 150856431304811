.InitialIcon {
    background-color: var(--blue);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
/* 
.SelectedImage {
    border: 3px solid darkred;
} */
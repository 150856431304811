.Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    padding: 2px 0 2px 0;
}

.Item {
    min-width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/* .Row {
    padding: 5px 0;
} */


.Row span {
    font-weight: bold;
}

.Row input {
    font-size: .8rem;
}

.Row select {
    
    font-size: .8rem;
}





.ServiceSettingsContainer {
    padding: 0 100px;
    /* width: 90%; */
    /* margin: auto; */
}
.ServiceSettingsSubContainer {
    position: relative;
    margin-bottom: 50px;
    /* padding: 20px 0; */
    border-bottom: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: 2px 2px 10px grey;
    padding: 20px;
    background-color: white;
    margin: 20px auto;
    /* margin: auto; */
}

.ServiceSettingsSubSubContainer {
    position: relative;
    margin-bottom: 10px;
    /* padding: 20px 0; */
    border-bottom: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: 2px 2px 10px lightgrey;
    padding: 20px;
    background-color: white;
    margin: 20px auto;
    /* margin: auto; */
}

.Row {
    display: flex;
    flex-flow: row wrap;
    gap: 5px;
    justify-content: flex-start;
}

.HeaderButtonRowRight {
    position: absolute;
    top: 20px;
    right: 20px;
}

.SchoolId {
    min-width: 100px;
    padding: 5px;
}


  
.Pair {
    display: flex;
    margin: 10px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 5px lightgrey;
}

.Key {
    padding: 10px;
    background-color: #f2f2f2;
    font-weight: bold;
    flex-basis: 20%;
    text-align: right;
    height: 100%; 
}

.Value {
    padding: 10px;
    flex-basis: 70%;
}

.PairFlexContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 5px 0;
    align-items: center;
}

.PairFlexBasis {
    flex: 1 1 0;
    height: 100%;
}

.HoverPointer:hover { 
    cursor: pointer;
}

.Subtitle {
    color: darkslategray;
    font-size: .9rem;
    font-weight: 550;
    letter-spacing: .8px;
}

.Label {
    color: darkslategray;
    font-size: .7rem;
    font-weight: 500;
    letter-spacing: .5px;
}
.AccountOwner {
    background-color: white;
    /* width: %; */
    margin: auto;
    /* border: 1px solid lightgrey;  */
    /* margin-top: 10px; */
    /* border-radius: 5px; */
    /* box-shadow: 5px 5px 10px #504f4f; */
    padding: 10px;
    box-sizing: border-box;
    text-align: left;
    overflow-x: auto;
    font-size: 0.8rem;
}



.Form {
    font-size: .8rem;
    text-align: left;
}

.EnvWarning {
    color: white;
    background-color: darkred;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid white;
}

.WarningText {
    font-weight: 550;
    color: var(--red);
    font-style: italic;
}

.Today {
    font-weight: bold;
    font-size: 140%; 
    color: red;
}

.Test {
    font-weight: bold;
    font-size: 140%; 
    color: red;
}

.Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.Item {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.Radio {
    display: none;
}

.Radio ~ span {
    font-size: 3rem;
    filter: grayscale(100);
    cursor: pointer;
    transition: 0.3s;
}

.Radio:checked ~ span {
    filter: grayscale(0);
    font-size: 4rem;
}
.RoundButton {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    font-size: 2rem;
    /* line-height: 25px; */
    border: none;
    background-color: #17A2B8;
    color: white;
    font-weight: bold;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}


.SupportingEvidence {
    text-align: center;
}


.Row {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: flex-start;
    gap: 10px;
    padding: 2px 0 2px 0;
}


.Row span {
    font-weight: bold;
}

.Row input {
    font-size: .8rem;
}

.Row select {
    
    font-size: .8rem;
}


.Item {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: darkslategray;
    font-weight: bold;
    letter-spacing: .5px;
    font-size: .65rem;
}

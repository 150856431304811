.AdminRoll {
    position: relative;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    padding: 2px 0 2px 0;
}

.HeaderRow {
    display: flex;
    flex-direction: row;
    /* gap: 150px; */
    align-items: center;
    justify-content: space-between;
}
.HeaderRowRight {
    display: flex;
    flex-direction: row;
    /* gap: 150px; */
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: -5px;
    left: 0px;
    gap: 10px;
}

/* .HeaderButtonRow {
    position: absolute;
    top: 0;
    right: 0;
    gap: 5px;
    padding-bottom: 10px;
} */

.SuccessBackground {
    background-color: rgb(206, 244, 206);
    font-weight: bold;
    color: rgb(76, 76, 76);
    width: 10px;
}
.SuccessBackground2 {
    background-color: rgb(219, 236, 255);
    font-weight: bold;
    color: rgb(76, 76, 76);
    width: 10px;
}
.SuccessBackground3 {
    background-color: rgb(255, 253, 152);
    font-weight: bold;
    color: rgb(76, 76, 76);
    width: 10px;
}

.PendingBackground {
    background-color: rgb(71, 71, 71);
    font-weight: bold;
    color: white;
    width: 10px;
}

.WaitlistBackground {
    background-color: var(--amber-tinted);
    font-weight: bold;
    color: darkslategrey;
    width: 10px;
}

.CancelledBackground {
    background-color: rgb(195, 195, 195);
    font-weight: bold;
    color: white;
    width: 10px;

}

.WarningBackground {
    background-color: var(--red-tinted);
    font-weight: bold;
    color: white;
    width: 10px;

}

/* .MinWidth1 {
    width: 200px;
} */

.DateText {
    font-size: 1.1rem;
    min-width: 170px;
    text-align: center;
}

.StickyHeader {
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 50;
    height: 145px;
}

.OptionModalTitle {
    font-weight: 600;
    font-size: .85rem;
}

.OptionModalSubtitle {
    font-weight: 550;
    font-size: .8rem;
    color: darkslategray;
}

.OptionModalSubtitle2 {
    font-weight: 500;
    font-size: .6rem;
    color: grey;
}
/* .SessionSignIns {
    width: 100%; 
    overflow-x: auto;
} */

.Title {
    /* text-align: center; */
    /* background-color:#091455; */
    background-color: var(--blue);
    color: white;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: space-between;
    /* font-size: .8rem; */
}

.TitleBusTheme {
    background-color: var(--amber-tinted);
}

.StickyHeader {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 50;
    /* outline:  solid white; */
    /* background-color: rgb(81, 81, 81); */
    /* color: white; */
}



.TitleInfo {

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.TitleSelects {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.RollSelect {
    /* width: 33%; */
    /* max-width: 150px; */
    /* min-width: 30%; */
    flex-grow: 1;
    margin: 0 2px;
    font-size: .7rem;
    font-weight: bold;
    z-index: 90;
}

.RollSelect select {
    font-size: .6rem;

}


.TitleText {
    /* padding-left: 10px; */
    min-width: 170px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    display: inline-block;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: .5rem;
}

.Table {
    font-size: .5rem;
    /* font-weight: bold; */
    width: 100%;
    /* border-collapse: collapse;  */
    border-collapse: separate; 
    border-spacing: 0;    
    /* table-layout: fixed; */
}

.TableSymbols {
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 10px;
}


.Row:hover {
    cursor: pointer;
}

.Row {
    font-weight: bold;
    border-bottom: 1px solid lightgrey;
    border-left: 4px solid transparent;
    position: relative;
}
.SelectedRow {
    /* border-left: 4px solid darkred; */
    box-shadow: inset 1px 1px 3px 5px var(--amber);
    /* outline: 5px solid var(--amber); */
    /* background-color: none;
    background-image: linear-gradient(45deg, rgba(128, 128, 128, 0.3) 25%, transparent 25%, transparent 75%, rgba(128, 128, 128, 0.3) 75%, rgba(128, 128, 128, 0.3)),
    linear-gradient(-45deg, rgba(128, 128, 128, 0.3) 25%, transparent 25%, transparent 75%, rgba(128, 128, 128, 0.3) 75%, rgba(128, 128, 128, 0.3));
    background-size: 30px 30px; */
    /* background-color: pink; */
}

/* box shadow inset doesn't seem to work on mobile (at least ios), so add offsetted outline instead */
@media only screen and (max-width: 733px) {
    .SelectedRow {
        outline: 5px solid var(--amber);
        outline-offset: -5px;
        outline-offset: -5px 0;
        box-shadow: none;
    }
}

.CheckboxContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 40px;
    cursor: pointer;
    font-size: 32px;
    height: 100%;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.CheckboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.CheckboxCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 40px;
    background-color: #eee;    
}

/* On mouse-over, add a grey background color */
.CheckboxContainer:hover input ~ .CheckboxCheckmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .CheckboxContainer input:checked ~ .CheckboxCheckmark {
    background-color: var(--amber-tinted);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .CheckboxCheckmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .CheckboxContainer input:checked ~ .CheckboxCheckmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .CheckboxContainer .CheckboxCheckmark:after {
    left: 11px;
    /* top: 3px; */
    width: 17px;
    height: 34px;
    border: solid white;
    border-width: 0 6px 6px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


.RowCheckedIn {
    background-color: var(--green-tinted);
    color: darkslategrey;
}
.RowCheckedOut {
    background-color: var(--purple-tinted);
    color: white;
}
.RowAbsent {
    background-color: var(--blue-tinted);
    color: white;
}

.Row td {
    /* width: 10px; */
    vertical-align: middle;
}



.CheckInBadge {
    padding: 5px 4px;
    background-color: #091455;
    border-radius: 5px;
    color: white;
}

.DetailRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5px;
    /* border-bottom: 1px solid lightgrey; */
    /* border-radius: 5px; */
    margin-bottom: 5px;
}

.DetailKey {
    font-weight: bold;
    min-width: 150px;
    color: #152153;
    min-width: 50%;
}

.ChildFlag {
    color: darkred;
    text-decoration: underline double;
    /* text-decoration: double; */
}

.StatusContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
/* 
.StatusChangedBySymbol {
    border: 1px solid black;
    border-radius: 2px;
    background-color: rgb(17, 68, 100);
    color: white;
    padding: 1px 4px;
    text-align: center;
} */

.StatusSymbolGuardian {
    border: 1.9px solid black;
    border-radius: 2px;
    color: black;
    padding: 0.5px 3px;
    text-align: center;
    font-weight: 700;
    background-color: white;
}
.StatusSymbolStaff {
    border: 1.9px solid black;
    border-radius: 7.5px;
    color: black;
    padding: .5px 5px;
    text-align: center;
    font-weight: 700;
    background-color: white;
}

.FlexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
}

.KioskStatusCard {
    padding: 5px;
    font-size: 0.75rem;
    border: 1px solid lightgrey;
    color: var(--darkgrey);
    border-radius: 5px;
    min-width: 18%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
}

.KioskStatusCard:hover {
    cursor: pointer;
}

.KioskStatusCardDark {
    color: white;
    background-color: var(--darkgrey);
}

.Image {
    width: 50px;
    /* border: 3px solid transparent; */
    border-radius: 50%;
}

/* .SelectedImage {
    box-sizing: border-box;
    border-radius: 50%;
    border: 3px solid darkred;
} */

.EmergencyContactContainer {
    border: 1px solid var(--lightgrey);
    border-radius: 5px;
    padding: 10px;
    margin: 10px auto;
    box-shadow: 1px 1px 5px #b1aeae;
}

.Footer {
    border-top: 2px solid rgb(242, 242, 242);
    background-color: white;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;    
}

.HomeIcon {
    height: 50px;
    margin-right: 5px;
}

.HomeIcon:hover{
    cursor: pointer;
}

.ChildTableHeader:hover{
    cursor: pointer;
}

.Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.Item {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.Radio {
    display: none;
}

.Radio ~ span {
    font-size: 3rem;
    filter: grayscale(100);
    cursor: pointer;
    transition: 0.3s;
}

.Radio:checked ~ span {
    filter: grayscale(0);
    font-size: 4rem;
}
.ParamsForm {
    padding: 10px 0;
    border-radius: 5px;
    margin: auto;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2px 0;
}

.CheckboxRow {
    margin: 10px auto;
    background-color: rgb(238, 237, 237);
    /* border: 1px solid lightgrey; */
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* padding: 10px 0; */
}

.Label {
    font-weight: 550;
    letter-spacing: .5px;
    color: rgb(86, 86, 86);
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 225px;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-left: 5px;
    
}

@media only screen and (max-width: 480px) {
    .Row {
        padding: 5px 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
 
}

.Warning {
    border-radius: 5px;
    background-color: var(--red-tinted);
    margin: 2px auto;
    padding: 5px;
}

.WarningText {
    color: white;
}


.BlockDatePicker, 
.BlockDatePicker > div {
   width: 100%;
}
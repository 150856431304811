.Table {
    font-size: .5rem;
    /* font-weight: bold; */
    width: 100%;
    /* table-layout: fixed; */
}

.TableSymbols {
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 10px;
}


.Row:hover {
    cursor: pointer;
}

.Row {
    font-weight: bold;
    border-bottom: 1px solid lightgrey;
    border-left: 4px solid transparent;
    position: relative;
}
.SelectedRow {
    border-left: 4px solid darkred;
    /* background-color: none;
    background-image: linear-gradient(45deg, rgba(128, 128, 128, 0.3) 25%, transparent 25%, transparent 75%, rgba(128, 128, 128, 0.3) 75%, rgba(128, 128, 128, 0.3)),
    linear-gradient(-45deg, rgba(128, 128, 128, 0.3) 25%, transparent 25%, transparent 75%, rgba(128, 128, 128, 0.3) 75%, rgba(128, 128, 128, 0.3));
    background-size: 30px 30px; */

  }


.Row td {
    /* width: 10px; */
    vertical-align: middle;
}



.DetailRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5px;
    /* border-bottom: 1px solid lightgrey; */
    /* border-radius: 5px; */
    margin-bottom: 5px;
}

.DetailKey {
    font-weight: bold;
    min-width: 150px;
    color: #152153;
    min-width: 50%;
}

.ChildFlag {
    color: darkred;
    text-decoration: underline double;
    /* text-decoration: double; */
}

 

.Container {
    display: flex;
    flex-flow: row wrap;

}

.MaterialCard {
    padding: 10px;
    min-width: 350px;
    max-width: 450px;
}

.Row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 2px 0 2px 0;
}

.Label {
    min-width: 150px;
}

.Value {
    border-radius: 5px;
    border: 1px solid var(--lightgrey);
    min-width: 100px;
    text-align: center;
}
.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed; 
    left: 0;
    top: 0;
    background-color: black;
    opacity: .6;
    margin: auto;
    z-index: 600;
}

.BackdropSpinner {
    position: fixed;
    top: 50vh;
    left: 50vw;
    z-index: 650;
}
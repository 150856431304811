.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 90%;
    border: 1px solid lightgrey; 
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;
    padding: 15px;
    left: 5%;
    top: 15%;
    box-sizing: border-box;
    transition: all 0.3s ease-in;
    max-height: 70%;
    overflow-y: auto;
    font-size: .8rem;
}


@media (min-width: 733px) {
    .Modal {
        width: 800px;
        left: calc(50% - 400px);
        padding: 30px;
    }
}


.ModalLarge {
    width: 90%;
    left: 5%;
    max-height: 90%;
    top: 5%;
}

.ModalTall {
    min-height: 80%;
    top: 10%;
}

.ShowOnTop {
    z-index: 600;
}
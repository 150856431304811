.KioskUserFeedback {
    background-color: white;
    position: relative;
    /* width: %; */
    margin: auto;
    margin-top: 10px;
    border-radius: 5px;
    /* box-shadow: 5px 5px 10px #504f4f; */
    /* padding: 25px; */
    box-sizing: border-box;
    text-align: left;
    overflow-x: auto;
    font-size: 0.8rem;
}
.ParentData {
    position: relative;
    background-color: white;
    /* width: %; */
    margin: auto;
    /* border: 1px solid lightgrey;  */
    margin-bottom: 20px;
    border-radius: 5px;
    /* box-shadow: 5px 5px 10px #504f4f; */
    box-shadow: 1px 1px 5px #b1aeae;
    padding: 25px;
    box-sizing: border-box;
    text-align: left;
    overflow-x: auto;
    font-size: 0.8rem;
}

.HeaderButtonRow {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.MissingDataTitle {
    color: var(--red);
    margin: 10px 5px -15px 5px;
}

.MissingDataCard {
    color: var(--red);
    padding:  20px;
    box-shadow: 1px 1px 5px #b1aeae;
    border-radius: 5px;
    margin: 10px auto;
    
}

.ChildGroupAuthDescriptions {
    font-size: .6rem;
}

.OuterData {
    position: relative;
    padding: 20px;
    /* border: 1px solid lightgrey; */
    box-shadow: 1px 1px 5px #b1aeae;
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: var(--lightgrey);
}

.InnerData {
    padding: 10px;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-bottom: 30px;
    position: relative;
}

.Subtitle {
    font-size: .9rem;
    font-weight: 500;
    color: darkslategray;
}

.DataMissingWarning {
    border: 2px solid var(--red);
    border-radius: 5px;
}

.HeaderButtonRowRight {
    top: 15px;
    right: 15px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
    position: absolute;
}

.Warning {
    border-radius: 5px;
    background-color: var(--red-tinted);
    margin: 2px auto;
    color: white;
}

.WarningBorder {
    border-radius: 5px;
    border: 2px solid var(--red);
}

.WarningTextBold {
    color: var(--red);
    font-weight: bold;
}
.WarningText {
    color: var(--red);
    font-weight: 550;
}

.SelectorPadding {
    padding: 10px;
}
.CustomEmail {
    width: 100%;
    margin: auto;
    padding: 5px;
    color: #464646;
}

.TinyEditor {
    padding-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

.Editor {
    z-index: 1;
}

.HeaderButtonRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    gap: 5px;
    padding: 5px 0 15px 0;
}
.ChildButton {
    width: 49%;
}

.CustomEmailButtons {
    display: flex;
    justify-content: flex-start;
    /* gap: 5px; */
    /* padding: 5px 0 15px 0; */
}
.CustomEmailButtonsChild {
    padding-left: 5px;
}


th, td {
    padding-right: 20px;
    padding-bottom: 5px;
}
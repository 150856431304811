.Label {
    font-weight: bold;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 200px;
    
}

.Row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}
.Cabinet {
    position: relative;
    padding: 5px;
    /* max-width: 90%; */
    margin: auto;
    text-align: left;
}

.SelectContainer {
    /* padding: 20px; */
    background-color: white;
    /* border-radius: 5px; */
    /* border: 1px solid lightgrey;  */
}


.Label {
    font-weight: bold;
    font-size: .7rem;
}




.HeaderButtonRowRight {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 10px;
}
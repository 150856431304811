.Kiosk {
    position: relative;
    box-sizing: border-box;
    text-align: left;
    font-size: 0.8rem;
    max-width: 90%;
    /* border: 1px solid white; */
    margin: auto;
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 10px #504f4f;
    padding: 25px;
    box-sizing: border-box;
}

/* desktop */
@media only screen and (min-width: 733px) {
    .Kiosk {
        max-width: 800px;
    }
}



.AdminLoginButton {
    position: absolute;
    top: 25px;
    right: 25px;
}

.Link {
    color: blue;
    text-decoration: underline;
}

.Link:hover {
    cursor: pointer;
}

.ContactCard {
    padding: 10px; 
    margin: 10px auto;
    border-radius: 4px;
    border: 1px solid lightgrey;
    width: 100%;
}

.ContactCard:hover{
    cursor: pointer;
    background-color: lightcoral;
    color: white;
}
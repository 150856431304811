.Table {
    font-size: .5rem;
    /* table-layout:fixed; */
    width: 100%;
    /* border-collapse: collapse; */
}

.Header {
    text-transform: uppercase;
    background-color: rgb(100, 100, 100);
    color: white;
}

.HeaderItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 55px;
}

.HeaderItemNewLine {
    text-transform: capitalize;
}


.SelectFilter {
    font-size: .5rem;
    padding: 0;
    height: 20px;
}

.Row {
    white-space: nowrap;
    
    /* white-space: normal; */
}

.HeaderButtonRowRight {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-bottom: 5px;
}
.HeaderButtonRowLeft {
    position: absolute;
    padding-top: 2px;
    padding-left: 2px;
    font-weight: bold;
    font-size: .65rem;
    letter-spacing: -1.5px;
    /* color: rgb(75, 75, 75) */
    color: white;
    z-index: 60;
    /* top: 5px; */
    /* display: flex; */
    /* justify-content: flex-start; */
    /* gap: 5px; */
    /* padding-bottom: 5px; */
}


/* sticky header class - currently hardcoded to sit below adminRoll header1, need to update to be dynamic in future if stickyHeader prop to be used outside of adminRoll */
.StickyHeaderAR {
    position: sticky;
    top: 140px;
    z-index: 55;
    background-color: white;
}

.StickyHeaderRAL {
    position: sticky;
    top: -30px;
    z-index: 55;
    background-color: white;
}

.StickyHeaderAR::after, .StickyHeaderRAL::after {
    content: "";
    position: absolute;
    bottom: 1px; /* negative value equal to border-bottom width */
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(186, 186, 186);
    z-index: -1;
}